import { useMemo } from 'react';
import { useAccommodationsEditorLeadStateQuery } from '@graphql/generated';
import { useIsHotelBlockAccommodationsFlagEnabled, useShouldShowSettingsOnComingSoonScreen } from '@apps/admin/common/hooks/useIsHotelBlockAccommodationsFlagEnabled';
import { LeadStateV2 } from '@graphql/generated';
import { useAuth, useEventUserRole } from '@shared/components/AuthProvider';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useFeatureValue } from '@shared/core/featureFlags';

export enum AccommodationsDefaultViewState {
  COMING_SOON = 'comingSoon',
  PITCH = 'pitch',
  IN_PROGRESS = 'inProgress',
  GET_ACCOMMODATIONS = 'getAccommodations',
  SUPER_ADMIN_EDIT = 'superAdminEdit',
  ADMIN_EDIT = 'adminEdit'
}

export function useGetAccommodationsEditorLeadState(eventHandle: string) {
  const { data: accommodationsLeadStateData, loading: accommodationsLeadStateLoading } = useAccommodationsEditorLeadStateQuery({ variables: { eventHandle }, batchMode: 'fast' });
  const { getValueString } = useQueryParamHelper();
  const queryLeadStateTest = getValueString('leadStateTest') as string;
  const isHotelBlockAccommodationsFlagEnabled = useIsHotelBlockAccommodationsFlagEnabled();
  const shouldShowSettingsOnComingSoonScreen = useShouldShowSettingsOnComingSoonScreen();
  const { currentUser } = useAuth();
  const { isAdmin, fetching } = useEventUserRole();
  const isSuperAdmin = currentUser && currentUser.profile?.superAdmin; // e.g. @withjoy.com email
  const leadState = accommodationsLeadStateData?.eventByName?.hotelRoomBlocks?.leadStateV2;
  const hasBookingLink = !!accommodationsLeadStateData?.eventByName?.hotelRoomBlocks?.bookingLink;
  const accommodationForNewEventsEnabled = !!accommodationsLeadStateData?.eventByName?.featureFlags?.includes('useAccommodationsPageForTravel');
  const hasRoomBlock =
    accommodationsLeadStateData?.eventByName?.hotelRoomBlocks?.reservedRoomBlocks && accommodationsLeadStateData?.eventByName?.hotelRoomBlocks?.reservedRoomBlocks.length !== 0;
  const hidePageSettingsAndFabCTA = !isSuperAdmin && shouldShowSettingsOnComingSoonScreen ? false : !isHotelBlockAccommodationsFlagEnabled && !isSuperAdmin;
  const adminEditAccommodationsEnabled = useFeatureValue('adminEditAccommodationsEnabled').value === 'on';
  const hotelTileGuestCountEnabled = useFeatureValue('accommodationsGuestCountEnabled').value === 'on';
  const hotelV2AccommodationsEnabled = useFeatureValue('hotelV2Accommodations').value === 'on';

  const accommodationsDefaultViewComponentState = useMemo<AccommodationsDefaultViewState | undefined>(() => {
    if (accommodationsLeadStateLoading || fetching) {
      return;
    }

    if (isSuperAdmin) {
      // Super admin to test UI
      if (queryLeadStateTest === 'inProgress' && !hotelV2AccommodationsEnabled) {
        return AccommodationsDefaultViewState.IN_PROGRESS;
      }
      if (queryLeadStateTest === 'getAccommodations') {
        return AccommodationsDefaultViewState.GET_ACCOMMODATIONS;
      }
      if (queryLeadStateTest === 'adminEdit') {
        return AccommodationsDefaultViewState.ADMIN_EDIT;
      }
      return AccommodationsDefaultViewState.SUPER_ADMIN_EDIT;
    }

    if (hotelV2AccommodationsEnabled) {
      return AccommodationsDefaultViewState.ADMIN_EDIT;
    }

    if (!isHotelBlockAccommodationsFlagEnabled || shouldShowSettingsOnComingSoonScreen) {
      return AccommodationsDefaultViewState.COMING_SOON;
    }

    // no room block, is not a lead - “Pitch Screen“
    if (!leadState) {
      return AccommodationsDefaultViewState.PITCH;
    }

    if (leadState === LeadStateV2.InProgress) {
      // no room block, is a lead, but does not yet have a booking link - “In Progress Screen“
      if (!hasRoomBlock && !hasBookingLink) {
        return AccommodationsDefaultViewState.IN_PROGRESS;
      }
    }

    // Has a booking link, but Concierge hasn’t updated Accom page - “Get the Accom Page Screen”
    if (hasBookingLink && !hasRoomBlock) {
      return AccommodationsDefaultViewState.GET_ACCOMMODATIONS;
    }

    // has room block, and Concierge _has_ updated Accom page - “Regular Accom Editor Screen”
    if (hasRoomBlock) {
      return AccommodationsDefaultViewState.ADMIN_EDIT;
    }

    return AccommodationsDefaultViewState.COMING_SOON;
  }, [
    accommodationsLeadStateLoading,
    fetching,
    isSuperAdmin,
    hotelV2AccommodationsEnabled,
    isHotelBlockAccommodationsFlagEnabled,
    shouldShowSettingsOnComingSoonScreen,
    leadState,
    hasBookingLink,
    hasRoomBlock,
    queryLeadStateTest
  ]);

  const enabledAdminGuestSiteBannerDialog = useMemo<boolean>(() => {
    if (accommodationsLeadStateLoading || fetching || (!leadState && hasRoomBlock)) {
      return false;
    }
    return !leadState && (isAdmin || !!isSuperAdmin) && isHotelBlockAccommodationsFlagEnabled;
  }, [accommodationsLeadStateLoading, fetching, leadState, hasRoomBlock, isAdmin, isSuperAdmin, isHotelBlockAccommodationsFlagEnabled]);

  const disableTravelItemUpdateDelete = useMemo<boolean>(() => {
    if (fetching) {
      return true;
    }
    if (queryLeadStateTest === 'adminEdit') {
      return true;
    }
    return !isSuperAdmin && !adminEditAccommodationsEnabled;
  }, [fetching, isSuperAdmin, queryLeadStateTest, adminEditAccommodationsEnabled]);

  const hideAccomPreviewUntilFlagEnabled = useMemo<boolean>(() => {
    if (fetching) {
      return true;
    }
    if (isSuperAdmin) {
      return false;
    }

    return isAdmin && !isHotelBlockAccommodationsFlagEnabled;
  }, [fetching, isSuperAdmin, isAdmin, isHotelBlockAccommodationsFlagEnabled]);

  return {
    hideAccomPreviewUntilFlagEnabled,
    enabledAdminGuestSiteBannerDialog,
    accommodationsDefaultViewComponentState,
    accommodationsLeadStateLoading,
    hidePageSettingsAndFabCTA,
    disableTravelItemUpdateDelete,
    isSuperAdmin: !!isSuperAdmin,
    accommodationForNewEventsEnabled,
    hotelTileGuestCountEnabled
  };
}
