import { EventDesignFragment, EventPageType } from '@graphql/generated';
import globalWindow from '@shared/core/globals';
import { useEffect } from 'react';
import { Subject } from 'rxjs';
import { isInIframe } from './isInIframe';
import { config } from '@static/js/env.config';
import { getNodeEnv } from './getEnvironmentVariables';
import { ActiveSuggestionIdentifiers } from '@apps/suggestions/components/SuggestionCard';
import { PreviewDevice, UserDesignPreferences } from '@apps/admin/routes/WebsiteDesignerV2/WebsiteDesigner.types';
import { AuxPhoto } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/components/AuxFrame/AuxFrame.types';

const { isDevelopment } = getNodeEnv();
/**
 * TODO: Deprecate once we make the full shift from bliss -> charm.
 */
export const LegacyPreviewTypes = {
  ACTION_FROM_LIST: 'fromList' as 'fromList',
  ACTION_FROM_USER_PREFERENCES: 'fromWebsiteDesignerPayload' as 'fromWebsiteDesignerPayload',
  ACTION_ON_INITIALIZED: 'previewInitialized' as 'previewInitialized',
  ACTION_SET_PREVIEW_TYPE: 'setPreviewType' as 'setPreviewType',
  ACTION_SWITCH_TO_PANEL_TAB: 'switchToPanelTab' as 'switchToPanelTab',
  ACTION_SWITCH_PREVIEW_DEVICE: 'switchToPreviewDevice' as 'switchToPreviewDevice',
  ACTION_ADMIN_EVENT_PAGE_CHANGE: 'adminEventPageChange' as 'adminEventPageChange',
  SOURCE_JOY_PREVIEW: 'joyPreview' as 'joyPreview'
} as const;

export type LegacyPreviewTypes = typeof LegacyPreviewTypes[keyof typeof LegacyPreviewTypes];
type LegacyPreviewProperty =
  | 'coverPhoto'
  | 'currentPageType'
  | 'suggestedColor'
  | 'layout'
  | 'backgroundType'
  | 'theme'
  | 'color'
  | 'font'
  | 'enableBackgroundTranslucency'
  | 'switchToPanelTab'
  | 'switchToPreviewDevice'
  | 'enablePhotoRepositionFeature'
  | 'isScreenMobile'
  | 'isCompactMode'
  | 'setWelcomePageCompact';
type LegacyPreviewMessage<T extends LegacyPreviewProperty, V> = Readonly<{
  key: T;
  value: V;
}>;

export type LegacyCoverPhotoMessage = LegacyPreviewMessage<
  'coverPhoto',
  {
    /**
     * page slug
     *
     * @example
     * welcome
     */
    page: EventPageType;
    action: 'reset' | 'update';
    asset: Maybe<{
      assetId: string | number;
      url: string;
      height: number;
      width: number;
      layout?: {
        alignX: string;
        alignY: string;
      };
    }>;
  }
>;

type LegacyCurrentPageMessage = LegacyPreviewMessage<'currentPageType', EventPageType | string>;
type LegacySuggestedColorMessage = LegacyPreviewMessage<'suggestedColor', boolean>;
type LegacyIsCompactModeMessage = LegacyPreviewMessage<'isCompactMode', boolean>;
type LegacyIsScreenMobileMessage = LegacyPreviewMessage<'isScreenMobile', boolean>;
type LegacySetWelcomePageCompact = LegacyPreviewMessage<'setWelcomePageCompact', boolean>;
type LegacyThemeMessage = LegacyPreviewMessage<'theme', string>;
type LegacyColorMessage = LegacyPreviewMessage<'color', { r: number; g: number; b: number; a: number }>;
type LegacyLayoutMessage = LegacyPreviewMessage<'layout', 'brannan' | 'aloha'>;
type LegacyBackgroundTypeMessage = LegacyPreviewMessage<'backgroundType', 'theme' | 'classic' | 'minimal' | 'modern'>;
type LegacyBackgroundTranslucencyMessage = LegacyPreviewMessage<'enableBackgroundTranslucency', boolean>;
type LegacyFontMessage = LegacyPreviewMessage<'font', string>;
type LegacySwitchToPanelTab = LegacyPreviewMessage<'switchToPanelTab', 'cardFeed' | 'websitePreview'>;
type LegacySwitchToPreviewDevice = LegacyPreviewMessage<'switchToPreviewDevice', 'mobile' | 'desktop'>;
type LegacyEnablePhotoReposition = LegacyPreviewMessage<'enablePhotoRepositionFeature', { isEnabled: boolean; previewDevice?: PreviewDevice }>;

export type LegacyPreviewIframeMessage =
  | LegacyCoverPhotoMessage
  | LegacyCurrentPageMessage
  | LegacySuggestedColorMessage
  | LegacyThemeMessage
  | LegacyColorMessage
  | LegacyLayoutMessage
  | LegacyBackgroundTypeMessage
  | LegacyBackgroundTranslucencyMessage
  | LegacyFontMessage
  | LegacySwitchToPanelTab
  | LegacySwitchToPreviewDevice
  | LegacyEnablePhotoReposition
  | LegacyIsScreenMobileMessage
  | LegacyIsCompactModeMessage
  | LegacySetWelcomePageCompact;

//////////////////////////////////////////////////////////////////////////

type PreviewMessage<T extends string, Source extends 'joy' | 'joyPreview' = 'joy'> = Readonly<{ action: T; source: Source }>;

type PreviewMessageWithPayload<T extends string, V extends unknown, Source extends 'joy' | 'joyPreview' = 'joy'> = Readonly<{
  source: Source;
  action: T;
  value: V;
}>;

type MessageSetPreviewType = PreviewMessageWithPayload<'setPreviewType', 'mobile' | 'desktop'>;
type MessagePreviewInitialized = PreviewMessage<'previewInitialized'>;

type MessageFromList = PreviewMessageWithPayload<'fromList', ReadonlyArray<LegacyPreviewIframeMessage>>;
export type MessageAdminEventPageChange = PreviewMessageWithPayload<'adminEventPageChange', EventPageType | string>;
type MessageSwitchToPanelTab = PreviewMessageWithPayload<'switchToPanelTab', 'cardFeed' | 'websitePreview'>;
type MessageSwitchToPreviewDevice = PreviewMessageWithPayload<'switchToPreviewDevice', 'mobile' | 'desktop'>;
type MessagePreviewActionInteraction = PreviewMessageWithPayload<'previewActionInteraction', 'viewSiteButton' | 'shareButton' | 'repositionPhotoButton', 'joyPreview'>;

export type MessageFromDesignerPayload = PreviewMessageWithPayload<
  'fromWebsiteDesignerPayload',
  { eventDesign: EventDesignFragment; preferences: UserDesignPreferences; meta: { isMobile: boolean } }
>;

export type EditPhotoData = {
  photo: AuxPhoto | null | undefined;
  pageId: string;
  page: Maybe<EventPageType>;
};

export type EditTextData = {
  textContent: string;
};

type InlineEditActions = 'editPhoto' | 'editText';

type InlineEditData = {
  editPhoto: EditPhotoData;
  editText: EditTextData;
};

type InlineEditingPayload<T extends InlineEditActions> = {
  action: T;
  inlineEditData: InlineEditData[T];
};

export type PreviewScaleChange = PreviewMessageWithPayload<'previewScaleChange', { scale: number }, 'joy'>;

export type InlineEditingMessageFromPreview<T extends InlineEditActions> = PreviewMessageWithPayload<'inlineEditingInteraction', InlineEditingPayload<T>, 'joyPreview'>;

export type ScaleRequestFromPreview = PreviewMessage<'requestIframeScaleValue', 'joyPreview'>;

export type IframeParentFocus = PreviewMessage<'iframeParentFocus', 'joy'>;

export type PreviewIframeMessage =
  | MessageSetPreviewType
  | MessagePreviewInitialized
  | MessageFromDesignerPayload
  | MessageFromList
  | MessageAdminEventPageChange
  | MessageSwitchToPanelTab
  | MessageSwitchToPreviewDevice
  | MessagePreviewActionInteraction
  | PreviewMessageWithPayload<'dashboardPanelInitialized', { hasUserInteractedWithPanelTabs: boolean }, 'joyPreview'>
  | PreviewMessageWithPayload<'suggestionCardInteraction', ActiveSuggestionIdentifiers, 'joyPreview'>
  | InlineEditingMessageFromPreview<'editPhoto'>
  | InlineEditingMessageFromPreview<'editText'>
  | PreviewMessageWithPayload<'togglePreviewButton', { show: boolean }, 'joyPreview'>
  | PreviewScaleChange
  | ScaleRequestFromPreview
  | IframeParentFocus;

export type SendMessageReducerAction = ReducerActionWithPayload<
  'sendMessage',
  {
    message: PreviewIframeMessage;
  }
>;

export type PreviewAction = ReducerAction<'resize'> | SendMessageReducerAction;

export const previewMessageBusSubject = new Subject<PreviewAction>();

//////////////////////////////////////////////////////////////////////////

export type DesignUpdateEventHandler = (message: MessageFromDesignerPayload | MessageFromList) => void;

export type UsePreviewListenerArgs = {
  onDesignUpdate: DesignUpdateEventHandler;
  onAdminEventPageChange: (message: MessageAdminEventPageChange) => void;
  onScaleChange?: (message: PreviewScaleChange) => void;
};

export type UseParentWindowListenerArgs = {
  onEditPhotoEventMessage?: (message: InlineEditingMessageFromPreview<'editPhoto'>) => void;
  onTogglePreviewEventMessage?: (message: PreviewMessageWithPayload<'togglePreviewButton', { show: boolean }, 'joyPreview'>) => void;
  onPreviewInitialised?: (message: MessagePreviewInitialized) => void;
  onRequestIframeScaleValue?: (message: ScaleRequestFromPreview) => void;
};

export const getTargetOrigin = () => {
  const { document, location } = globalWindow;
  if (isDevelopment) {
    if (document && location && typeof URL !== 'undefined') {
      const url = new URL(config.previewTargetOrigin || `${location.protocol}//${document.domain}`);
      if (!url.port) {
        url.port = location.port;
      }
      return url.origin;
    }
  }
  return `https://${document?.domain}`;
};

const ACCEPTED_ORIGINS = new Set(
  [config.clientUri, getTargetOrigin(), ...(isDevelopment ? ['http://localhost:8474', 'http://localhost:8000', 'http://localhost:9000'] : [])].filter(x => x)
);
const isValidRequestOrigin = (origin: string) => {
  return ACCEPTED_ORIGINS.has(origin);
};

export const createPreviewMessageEventHandler = (cb: (message: PreviewIframeMessage, ev: MessageEvent) => void, source: 'joy' | 'joyPreview' = 'joy') => (
  msgEvent: MessageEvent
) => {
  if (!isValidRequestOrigin(msgEvent.origin)) {
    return;
  }

  if (msgEvent.data !== 'undefined' && msgEvent.data) {
    try {
      const message: PreviewIframeMessage = typeof msgEvent.data === 'object' ? msgEvent.data : JSON.parse(msgEvent.data);
      if (message?.source === source) {
        cb(message, msgEvent);
      }
    } catch (e) {
      console.error(msgEvent.data, e);
    }
  }
};

const POST_MESSAGE_TARGET_ORIGIN = isDevelopment ? '*' : getTargetOrigin();

export const usePreviewListener = (args: UsePreviewListenerArgs) => {
  const { onDesignUpdate, onAdminEventPageChange } = args;
  const isPreviewing = isInIframe();

  useEffect(() => {
    if (!isPreviewing) {
      return;
    }

    const processMessage = (message: PreviewIframeMessage) => {
      switch (message.action) {
        case 'fromList':
        case 'fromWebsiteDesignerPayload': {
          onDesignUpdate(message);
          break;
        }
        case 'adminEventPageChange': {
          onAdminEventPageChange(message);
        }
      }
    };

    const previewSub = previewMessageBusSubject.subscribe({
      next: action => {
        if (action.type === 'sendMessage') {
          processMessage(action.payload.message);
        }
      }
    });

    const receiveMessage = createPreviewMessageEventHandler(processMessage);
    globalWindow.addEventListener?.('message', receiveMessage, false);
    globalWindow.top?.postMessage({ action: 'previewInitialized', source: 'joyPreview' }, POST_MESSAGE_TARGET_ORIGIN);

    return () => {
      previewSub?.unsubscribe();
      globalWindow.removeEventListener?.('message', receiveMessage, false);
    };
  }, [isPreviewing, onDesignUpdate, onAdminEventPageChange]);

  return {
    isPreviewing
  };
};

export const sendMessageToParentWindow = (message: PreviewIframeMessage) => {
  globalWindow.top?.postMessage(JSON.stringify({ ...message, source: 'joyPreview' }), POST_MESSAGE_TARGET_ORIGIN);
};

export const useParentWindowListener = (args: UseParentWindowListenerArgs) => {
  const { onEditPhotoEventMessage, onTogglePreviewEventMessage, onPreviewInitialised, onRequestIframeScaleValue } = args;
  const isPreviewing = isInIframe();

  useEffect(() => {
    if (isPreviewing) {
      return;
    }

    const processMessage = (message: PreviewIframeMessage) => {
      switch (message.action) {
        case 'inlineEditingInteraction': {
          switch (message.value.action) {
            case 'editPhoto':
              onEditPhotoEventMessage && onEditPhotoEventMessage(message as InlineEditingMessageFromPreview<'editPhoto'>);
              break;
          }
          break;
        }
        case 'togglePreviewButton': {
          onTogglePreviewEventMessage && onTogglePreviewEventMessage(message);
          break;
        }
        case 'previewInitialized': {
          onPreviewInitialised && onPreviewInitialised(message as MessagePreviewInitialized);
          break;
        }
        case 'requestIframeScaleValue': {
          onRequestIframeScaleValue && onRequestIframeScaleValue(message as ScaleRequestFromPreview);
        }
      }
    };

    const receiveMessage = createPreviewMessageEventHandler(processMessage, 'joyPreview');
    globalWindow.addEventListener?.('message', receiveMessage, false);

    return () => {
      globalWindow.removeEventListener?.('message', receiveMessage, false);
    };
  }, [isPreviewing, onEditPhotoEventMessage, onPreviewInitialised, onRequestIframeScaleValue, onTogglePreviewEventMessage]);

  return {
    isPreviewing
  };
};
