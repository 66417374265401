import React from 'react';
import { GuestTextComponentFragment } from '@graphql/generated';
import { Flex } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

interface RichTextProps {
  richTextComponentData: GuestTextComponentFragment;
}

export const RichText: React.FC<RichTextProps> = ({ richTextComponentData }) => {
  return (
    <Flex
      maxWidth={pxToRem(470)}
      textAlign="center"
      dangerouslySetInnerHTML={{
        __html: richTextComponentData.text || ''
      }}
    ></Flex>
  );
};
