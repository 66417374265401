import React from 'react';
import { EventPageFragment, EventPageType } from '@graphql/generated';
import { Box, BoxProps, ColumnsV2, Flex, TextV2 } from '@withjoy/joykit';
import { LayoutBrannanProps } from '../../LayoutBrannan';
import { pageColumnProps, pageWidgets, PreparedPages } from './Body.constants';
import { shouldRenderGraphicAccent } from '../../../layout.utils';
import { PrettyLink } from '@apps/guest/packages/layout-engine/components/PrettyLink';
import GraphicAccent from '@apps/guest/packages/layout-engine/components/GraphicAccent';
import { useSlapAdsTravelClosed } from '@apps/guest/packages/layout-engine/components/SlapAdsTravel';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';

export interface BodyProps extends Readonly<{ page: Maybe<EventPageFragment>; photo?: React.ReactNode } & LayoutBrannanProps & BoxProps> {}

const Body: React.FC<BodyProps> = ({ page, event, eventHandle, photo, ...restProps }) => {
  const slapAdsTravelClosed = useSlapAdsTravelClosed();
  const { isAdmin } = useEventUserRole();
  const { isNativeAccommodations, isAccommodations } = useGuestSiteState();
  if (!page) {
    return null;
  }
  const { type, pageTitle, subTitle, pageSlug, id } = page;
  const graphicAccent = event.eventDesign?.accent;

  const PageWidget = pageWidgets[type as PreparedPages];
  const isWelcomePage = type === EventPageType.welcome;
  const isSchedulePage = type === EventPageType.schedule;
  const isStoryPage = type === EventPageType.story;
  const isTravelPage = type === EventPageType.travel;
  const isCustomPage = type === EventPageType.custom;

  const pageContentMarkup = PageWidget && (
    <ColumnsV2.Column id={pageSlug} {...pageColumnProps[type as PreparedPages]} marginTop={isWelcomePage || isSchedulePage ? undefined : isCustomPage ? 0 : [10, null, 0]}>
      <PageWidget event={event} eventHandle={eventHandle} pageId={id} pageSlug={pageSlug} pageTitle={pageTitle} subTitle={subTitle} />
    </ColumnsV2.Column>
  );

  const graphicAccentMarkup = shouldRenderGraphicAccent(graphicAccent) && (
    <GraphicAccent id="graphic-accent" marginBottom={8} data-testid={'body-accent'} accentId={graphicAccent!} />
  );

  return (
    <ColumnsV2 {...restProps}>
      <ColumnsV2.Column
        style={isCustomPage ? { display: isNativeAccommodations && !subTitle ? 'none' : 'flex', justifyContent: 'center' } : {}}
        span={isCustomPage ? 12 : [12, 10]}
        offset={isCustomPage ? 0 : [0, 1]}
      >
        {photo}
        <Flex
          flexDirection={'column'}
          alignItems={'center'}
          marginTop={isCustomPage ? { _: 6, sm2: 9 } : 9}
          marginBottom={isStoryPage || (isAdmin && !slapAdsTravelClosed && isTravelPage) ? 0 : isCustomPage ? { _: 6, sm2: 9 } : 9}
          marginX={[6, null, 0]}
          maxWidth={isCustomPage ? '580px' : null}
        >
          {graphicAccentMarkup}
          {isWelcomePage && pageContentMarkup}
          {!isWelcomePage && !isStoryPage && !isNativeAccommodations && (!isCustomPage || isAccommodations) && (
            <TextV2
              data-testid={'body-pagetitle'}
              typographyVariant={'display4'}
              fontSize={isCustomPage ? { _: 'clamp(2rem,5cqi,3rem)', sm2: 'clamp(1.8rem,5cqi,3rem)' } : '3rem'}
              textAlign="center"
              wordBreak="break-word"
            >
              {pageTitle}
            </TextV2>
          )}
          {!isStoryPage && subTitle && (
            <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} marginTop={isWelcomePage ? 9 : 4} maxWidth={500} textAlign="center">
              <PrettyLink source={page.pageSlug}>{subTitle}</PrettyLink>
            </TextV2>
          )}
        </Flex>
      </ColumnsV2.Column>

      {!isWelcomePage && (
        <Box width={'100%'} marginX={[isCustomPage ? 0 : 7, null, 0]} display={'flex'} justifyContent={'center'}>
          {pageContentMarkup}
        </Box>
      )}
    </ColumnsV2>
  );
};

Body.displayName = 'Body';

export { Body };
