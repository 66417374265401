import { EventPageType, PhotoFragment } from '@graphql/generated';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import React, { useEffect, useState } from 'react';
import { CoverPhotoContainer } from '../Body/Body.styles';
import { SkeletonScreen, StyledCoverPhoto } from './CoverPhoto.styles';
import { InlineEditor } from '@shared/components';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { isInIframe } from '@shared/utils/isInIframe';

const _getImageRendition = (url: string) => {
  const imageUrl = new URL(url);
  imageUrl.searchParams.append('rendition', 'medium');
  return imageUrl.toString();
};

const { isTest } = getNodeEnv();

const LazyScreen: React.FC<{ src?: string; height: string | number; width?: string | number; children: (loaded: boolean) => JSX.Element }> = ({ height, width, children, src }) => {
  const [loaded, setLoaded] = useState(src === undefined || isTest || __SERVER__);

  const isPreviewing = isInIframe();

  const handeOnLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded && src) {
      const img = new Image();
      img.onload = handeOnLoad;
      img.src = _getImageRendition(src);
    }
  }, [loaded, src]);

  useEffect(() => {
    if (isPreviewing && loaded) {
      sendMessageToParentWindow({ action: 'requestIframeScaleValue', source: 'joyPreview' });
    }
  }, [isPreviewing, loaded]);

  // const memoizedImage = src ? <img alt="" src={src} style={{ display: 'none' }} onLoad={handeOnLoad} /> : null;

  return (
    <SkeletonScreen height={height} width={width} index={0} loaded={loaded}>
      {loaded ? children(loaded) : null}
    </SkeletonScreen>
  );
};

interface Props
  extends Readonly<{
    photo?: Maybe<PhotoFragment>;
    pageId?: string;
    pageType?: EventPageType;
  }> {}

export const CoverPhoto: React.FC<Props> = props => {
  const { photo, pageId, pageType } = props;

  const handleEditPhotoClick = () => {
    // send the message to the parent window to open the photo editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editPhoto', inlineEditData: { photo: photo, pageId: pageId || '', page: pageType } }
    });
  };

  return (
    <>
      {photo && (
        <LazyScreen key={photo.id} src={photo.url} width={(600 * photo.width) / photo.height} height={600}>
          {() => (
            <InlineEditor
              key={`inline-edit-photo-${pageId || ''}-${photo?.id || ''}`}
              elementLabel="Photo"
              wrapperType="actionInside"
              actionData={{
                editPhoto: handleEditPhotoClick
              }}
            >
              <CoverPhotoContainer
                data-width={photo.width}
                data-height={photo.height}
                data-testid={'body-coverphoto'}
                position={'relative'}
                photo={photo}
                maxHeight={[null, null, null, 600]}
                width={'100%'}
              >
                <StyledCoverPhoto backgroundSize={'contain'} url={_getImageRendition(photo.url)} />
              </CoverPhotoContainer>
            </InlineEditor>
          )}
        </LazyScreen>
      )}
    </>
  );
};
