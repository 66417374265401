import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from '@shared/core';
import { pxToRem } from '@withjoy/joykit/theme';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { SkeletonThumbnail } from '@shared/components/Skeleton';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { Flex, TextV2, FabButton, Box, Divider } from '@withjoy/joykit';
import { PointOnMapType, PointsOnMapFragment } from '@graphql/generated';
import { HeartStroke, ZoomContract, ZoomExpand } from '@withjoy/joykit/icons';
import { JoyInteractableMap, MAP_CARDS_PORTAL_ID, MapPin } from '@shared/components/JoyInteractableMap';
import { useWindowResizeObserver, useWindowResizeSubscriber } from '@shared/utils/hooks/useWindowResize';
import { getCalculatedCenterAndZoom } from '@shared/components/JoyInteractableMap/JoyInteractableMap.utils';
import { JoyInteractableMapProvider } from '@shared/components/JoyInteractableMap/JoyInteractableMap.provider';

import { AccommodationsMapCard } from './AccommodationsMapCard';
import { useCustomPageTelemetry } from '../../../../Custom.telemetry';
import { RemoveScroll, StyledTitle, mapSizeVariants } from './AccommodationsMap.styles';
import { AccommodationCombined } from '../Accommodations/Accommodation.types';

interface AccommodationMapProps {
  pageTitle?: string;
  pointsOnMap: PointsOnMapFragment[];
  accommodationsCombined: AccommodationCombined[];
  showFullScreenMap: boolean;
  isBrannanLayout?: boolean;
  onSelectHotel: (id: string) => void;
  locationBeingHovered?: string;
}

const AccommodationMap: React.FC<AccommodationMapProps> = ({
  pageTitle,
  pointsOnMap,
  accommodationsCombined,
  showFullScreenMap,
  isBrannanLayout,
  onSelectHotel,
  locationBeingHovered
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const markers = accommodationsCombined
    .map(roomBlock => ({
      lat: roomBlock.latitude || 0,
      lng: roomBlock.longitude || 0
    }))
    .concat(pointsOnMap.map(pointOnMap => ({ lat: pointOnMap.latitude || 0, lng: pointOnMap.longitude || 0 })))
    .filter(marker => marker.lat !== 0 || marker.lng !== 0);
  const destination = pointsOnMap.find(pointOnMap => pointOnMap.type === PointOnMapType.venue);
  const { center, bounds } = getCalculatedCenterAndZoom(markers);
  const [latlng, setLatlng] = useState<{ lat: number; lng: number }>(center);
  const [activeMarker, setActiveMarker] = useState<string | null>(null);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));

  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const telemetry = useCustomPageTelemetry();

  const buttonSize = isFullScreen ? 48 : 40;
  const mapSize = isFullScreen ? mapSizeVariants(screenHeight, isBrannanLayout).fullscreen : mapSizeVariants(screenHeight, isBrannanLayout).normalscreen;

  const handleOnCenterChanged = useEventCallback((lat: number, lng: number) => setLatlng({ lat, lng }));

  const toggleFullScreen = useEventCallback((fullscreen: boolean) => {
    setIsFullScreen(fullscreen);
  });

  useWindowResizeObserver();
  useWindowResizeSubscriber(size => {
    setScreenHeight(size.height || window.innerHeight);
  });

  useEffect(() => {
    setIsFullScreen(showFullScreenMap);
  }, [showFullScreenMap]);

  const generateHotelTelemetryInfo = (hotel: AccommodationCombined) => {
    const { displayName, isFavorite, pricePerNight, strikeoutPricePerNight } = hotel;
    const telemetryHotelInfo = {
      hotelName: displayName || '',
      isHotelPreferred: isFavorite || false,
      hotelPrice: pricePerNight || undefined,
      hotelStrikeThroughPrice: strikeoutPricePerNight || undefined
    };
    return telemetryHotelInfo;
  };

  const handleHotelPinSelected = (hotel: AccommodationCombined) => {
    telemetry.hotelPinSelected('accommodations', generateHotelTelemetryInfo(hotel));
  };

  const handleHotelPinCardSelected = (e: React.MouseEvent, hotel: AccommodationCombined) => {
    e.stopPropagation(); // prevent map click telemetry event from firing
    telemetry.hotelPinCardSelected('accommodations', generateHotelTelemetryInfo(hotel));
  };

  const handleAccommodationsMapClicked = () => {
    telemetry.accommodationsMapClicked('accommodations');
  };

  const handleMapExpanded = () => {
    telemetry.mapExpanded('accommodations');
  };

  const handleMapContracted = () => {
    telemetry.mapContracted('accommodations');
  };

  return (
    <>
      {isFullScreen && <RemoveScroll />}
      <Divider display="flex" margin={isBrannanLayout && isMobile ? 'auto' : 'inherit'} maxWidth={isBrannanLayout && isMobile ? '520px' : 'inherit'} />
      <Flex
        paddingTop={isBrannanLayout && !isMobile ? 0 : 8}
        width={'100%'}
        maxWidth={isBrannanLayout && isMobile ? pxToRem(520) : 'inherit'}
        margin={isBrannanLayout && isMobile ? 'auto' : 'inherit'}
      >
        <Flex {...mapSize} borderRadius="12px" overflow="hidden">
          <JoyInteractableMapProvider>
            <JoyInteractableMap
              center={latlng}
              bounds={bounds}
              zoom={13}
              autoCenterAndZoom={markers.length > 1}
              gestureHandling={isFullScreen ? 'greedy' : 'cooperative'}
              onClick={() => {
                handleAccommodationsMapClicked();
                setActiveMarker(null);
              }}
              onCenterChanged={handleOnCenterChanged}
            >
              {isFullScreen && pageTitle && (
                <Box padding={6} width="100%" textAlign="center" position="absolute" backgroundColor="white">
                  <StyledTitle marginX={'0.24rem'} typographyVariant="display4" color="mono12" fontSize={{ _: 40, sm2: 48 }} fontWeight={{ _: 52, sm2: 62.4 }}>
                    {pageTitle}
                  </StyledTitle>
                </Box>
              )}
              {(!isBrannanLayout || (isBrannanLayout && isMobile)) && (
                <FabButton
                  right={5}
                  top={isFullScreen ? 124 : 5}
                  padding={0}
                  position="absolute"
                  variant="ghostShadow"
                  minWidth={pxToRem(buttonSize)}
                  maxWidth={pxToRem(buttonSize)}
                  width={pxToRem(buttonSize)}
                  height={pxToRem(buttonSize)}
                  onClick={() => {
                    setActiveMarker(null);
                    toggleFullScreen(!isFullScreen);
                    isFullScreen ? handleMapContracted() : handleMapExpanded();
                  }}
                >
                  {isFullScreen ? <ZoomContract size="md" /> : <ZoomExpand size="sm" />}
                </FabButton>
              )}
              {pointsOnMap.map(pointOnMap => (
                <Fragment key={pointOnMap.id}>
                  {(pointOnMap.latitude || pointOnMap.longitude) && (
                    <MapPin
                      position={{ lat: pointOnMap.latitude || 0, lng: pointOnMap.longitude || 0 }}
                      showOnTop
                      label={
                        <Flex justifyContent="center" alignItems="center" columnGap={2}>
                          <HeartStroke size="sm" />
                          <TextV2 typographyVariant="label3" fontSize={pxToRem(13)} fontWeight={700} fontFamily="Inter UI">
                            {accTrans.weddingVenue()}
                          </TextV2>
                        </Flex>
                      }
                    />
                  )}
                </Fragment>
              ))}
              {isMobile && <Box id={MAP_CARDS_PORTAL_ID} position="fixed" width={`calc(100% - ${pxToRem(32)})`} padding={5} bottom={0} left={0} />}
              {accommodationsCombined.map(hotel => {
                return (
                  <AccommodationsMapCard
                    key={hotel.id}
                    hotel={hotel}
                    isBrannanLayout={isBrannanLayout}
                    activeMarker={activeMarker}
                    destination={destination}
                    isFullScreen={isFullScreen}
                    onSelectHotel={onSelectHotel}
                    setIsFullScreen={toggleFullScreen}
                    setActiveMarker={setActiveMarker}
                    handleHotelPinSelected={handleHotelPinSelected}
                    handleHotelPinCardSelected={handleHotelPinCardSelected}
                    locationBeingHovered={locationBeingHovered}
                  />
                );
              })}
            </JoyInteractableMap>
          </JoyInteractableMapProvider>
        </Flex>
      </Flex>
    </>
  );
};

AccommodationMap.displayName = 'AccommodationMap';

const AccommodationMapSkeleton: React.FC<{ isBrannanLayout?: boolean }> = ({ isBrannanLayout }) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useWindowResizeObserver();
  useWindowResizeSubscriber(size => {
    setScreenHeight(size.height || window.innerHeight);
  });

  return (
    <Flex width="100%" display={{ _: 'flex', sm2: 'flex' }} maxWidth={isBrannanLayout ? { _: '520px', sm2: '100%' } : {}}>
      <SkeletonThumbnail
        overflow="hidden"
        height={isBrannanLayout ? { _: pxToRem(300), sm2: pxToRem(screenHeight - 64), sm4: pxToRem(screenHeight - 64) } : { _: pxToRem(273), sm2: pxToRem(396), sm4: pxToRem(329) }}
        marginBottom={32}
        overrides={{
          PlaceholderRoot: {
            props: {
              width: '100%',
              paddingTop: '75%',
              backgroundColor: 'white',
              borderRadius: '12px !important'
            }
          }
        }}
      />
    </Flex>
  );
};

export { AccommodationMap, AccommodationMapSkeleton };
