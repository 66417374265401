// import { RoutesProvider } from '@shared/core';
import React, { Dispatch, SetStateAction } from 'react';
import { LayoutAloha } from '../../packages/layout-engine/layouts/LayoutAloha';
import { LayoutBrannan } from '../../packages/layout-engine/layouts/LayoutBrannan';
import { useGuestSiteController } from './GuestSite.controller';
import { PreviewListener } from './PreviewListener';
import { DesignLayoutType } from '@graphql/generated';
import { ScrollToProvider } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/providers/ScrollToProvider/ScrollToProvider';
import { GuestSiteEvent } from '@apps/guest/packages/layout-engine/layouts/layout.types';
import { StaticContext } from '@react-router';
import { JoyKitThemeProvider } from '@withjoy/joykit';
import JustFonts from '@apps/guest/packages/layout-engine/components/JustFonts';
import { GuestSiteHead } from './GuestSiteHead';
import { GuestSiteDebugger } from '@apps/guest/components/GuestSiteDebugger';
import { ActionBar } from '@apps/guest/packages/layout-engine/components/ActionBar';
import { UnlockDialogProvider } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialogProvider';
import { LayoutProvider } from '@apps/guest/packages/layout-engine/layouts/LayoutProvider';
import { Announcement } from '@apps/guest/packages/layout-engine/components/Announcement';
import { BannerPublishReminder } from '@apps/guest/packages/layout-engine/components/BannerPublishReminder';
import { wrapWithGuestSiteHealthProvider } from './GuestSite.health';
import { GuestSiteFeatureSet } from '@apps/guest/packages/layout-engine/components/GuestSiteFeatureSet/GuestSiteFeatureSet';
import { TelemetryProvider as HotelBookingTelemetryProvider } from '../HotelBooking/HotelBooking.telemetry';
import { GuestSiteAccommodationsState } from './GuestSite.controller';
import HotelBooking from '../HotelBooking/routes/HotelBooking';
import { useNativeState } from '@shared/core/native';
import { GuestSiteStateProvider, useGuestSiteState } from './GuestSite.state.provider';
import { AdminLeadGenBanner } from './components/AdminLeadGenBanner';
import { AdminLeadGenDialog } from './components/AdminLeadGenDialog';

export interface GuestSiteLayoutProps
  extends Readonly<{
    eventHandle: string;
    event: GuestSiteEvent;
    layout: DesignLayoutType;
    setLoadingTheme?: Maybe<Dispatch<SetStateAction<boolean>>>;
    setIsLastPageVisible?: (value: boolean) => void;
  }> {}

const GuestSiteLayout: React.FC<GuestSiteLayoutProps> = ({ eventHandle, event, layout, setLoadingTheme, setIsLastPageVisible }) => {
  const layoutSwitch = () => {
    const layoutTypes = {
      [DesignLayoutType.aloha]: LayoutAloha,
      [DesignLayoutType.brannan]: LayoutBrannan
    };
    const Layout = layoutTypes[layout];

    return (event && <Layout setLoadingTheme={setLoadingTheme} setIsLastPageVisible={setIsLastPageVisible} eventHandle={eventHandle} event={event} />) || null;
  };

  return layoutSwitch();
};

export interface GuestSiteProps
  extends Readonly<{
    eventHandle: string;
    staticContext: StaticContext | undefined;
  }> {}

const GuestSiteBase: React.FC<GuestSiteProps> = wrapWithGuestSiteHealthProvider(({ eventHandle, staticContext }) => {
  const {
    event,
    layout,
    eventPageRoutes,
    isPreviewing,
    handleOnPreviewUpdate,
    handleOnRefetchData,
    theme,
    loading,
    justFonts,
    graphicAccent,
    shouldRenderActionBar,
    setLoadingTheme,
    setIsLastPageVisible,
    showBannerReminderChanges,
    onDismissReminder,
    onClickReminder,
    showAnnouncement,
    onAnnouncementDismiss,
    guestSiteAccommodationsState
  } = useGuestSiteController({
    eventHandle,
    staticContext
  });
  const { isNative } = useNativeState();
  const { showNoLeadDialog, enabledAdminGuestSiteBannerDialog, setShowNoLeadDialog } = useGuestSiteState();

  if (guestSiteAccommodationsState === GuestSiteAccommodationsState.LOADING) {
    return null;
  } else if (guestSiteAccommodationsState === GuestSiteAccommodationsState.GUESTSITE_ACCOMMODATIONS_SHOULD_REDIRECT) {
    return (
      <HotelBookingTelemetryProvider context={{ eventName: eventHandle }}>
        <HotelBooking eventHandle={eventHandle || ''} />;
      </HotelBookingTelemetryProvider>
    );
  }

  return (
    <>
      {enabledAdminGuestSiteBannerDialog && (
        <AdminLeadGenBanner show={enabledAdminGuestSiteBannerDialog} eventHandle={eventHandle} isBrannan={layout === DesignLayoutType.brannan} />
      )}
      {event && (
        <GuestSiteHead
          hideFromSearchEngines={event.settings.hideFromSearchEngines}
          eventDisplayName={event.info?.eventDisplayName}
          imageUrl={event.photo?.url}
          handle={event.website}
          ownerFirstName={event.info.ownerFirstName}
          ownerLastName={event.info.ownerLastName}
          fianceeFirstName={event.info.fianceeFirstName}
          pageData={event.pages}
          eventType={event.info.eventType}
        />
      )}
      <GuestSiteFeatureSet loadingGuestSite={loading}>
        {event && layout ? (
          <LayoutProvider layout={layout}>
            <JoyKitThemeProvider theme={theme}>
              <UnlockDialogProvider>
                {showAnnouncement && !isNative && (
                  <Announcement
                    onAnnouncementClosed={() => onAnnouncementDismiss(event.info.announcementBanner.title)}
                    content={<>{event.info.announcementBanner.message}</>}
                    title={event.info.announcementBanner.title}
                    graphicAccent={graphicAccent}
                  />
                )}
                <AdminLeadGenDialog showDialog={enabledAdminGuestSiteBannerDialog && showNoLeadDialog} eventHandle={eventHandle} openCloseDialog={setShowNoLeadDialog} />
                {justFonts ? (
                  <JustFonts />
                ) : (
                  <ScrollToProvider>
                    {isPreviewing && (
                      <PreviewListener eventPageRoutes={eventPageRoutes} eventDesign={event?.eventDesign} onRefetchData={handleOnRefetchData} onUpdate={handleOnPreviewUpdate} />
                    )}
                    <GuestSiteLayout setLoadingTheme={setLoadingTheme} eventHandle={eventHandle} event={event} layout={layout} setIsLastPageVisible={setIsLastPageVisible} />
                  </ScrollToProvider>
                )}
                {shouldRenderActionBar && <ActionBar eventId={event.id} />}
                {showBannerReminderChanges && <BannerPublishReminder handleDismiss={onDismissReminder} handleClickLink={onClickReminder} eventHandle={eventHandle} />}
              </UnlockDialogProvider>
            </JoyKitThemeProvider>
            <GuestSiteDebugger event={event} />
          </LayoutProvider>
        ) : null}
      </GuestSiteFeatureSet>
    </>
  );
});

const GuestSite: React.FC<GuestSiteProps> = props => {
  return (
    <GuestSiteStateProvider eventHandle={props.eventHandle}>
      <GuestSiteBase {...props} />
    </GuestSiteStateProvider>
  );
};

GuestSite.displayName = 'GuestSite';

export { GuestSite };
