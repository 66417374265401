export const addItem = <T>(source: Array<T>, item: T) => {
  return [...source, item];
};

export const removeItem = <T>(source: Array<T>, target: T) => {
  return source.filter(item => item !== target);
};

export const hasIntersection = (arr1: string[], arr2: string[]): boolean => {
  const arr1Set = new Set(arr1);
  return arr2.some(val => arr1Set.has(val));
};

export type HasFavoriteProperty = { isFavorite?: boolean | null };
export const sortByFavorite = <T extends HasFavoriteProperty>(list: T[]): T[] => {
  const isFavorite = list?.find(item => item.isFavorite);

  if (isFavorite) {
    const filtered = list?.filter(item => !item.isFavorite);
    return [{ ...isFavorite }, ...filtered];
  }
  return list;
};

export type HasIdProperty = { id?: string };
export const sortByOrderList = <T extends HasIdProperty>(list: T[], orderList?: string[]): T[] => {
  let orderedList: typeof list = [];
  const listItemMap = new Map(list.map(item => [item.id, item]));
  if (orderList && orderList.length > 0) {
    orderList.forEach(orderId => {
      if (listItemMap.has(orderId)) {
        orderedList.push(listItemMap.get(orderId)!);
        listItemMap.delete(orderId);
      }
    });
    listItemMap.forEach(item => orderedList.push(item));
  } else {
    orderedList = [...list];
  }
  return orderedList?.filter(orderedListItem => orderedListItem !== undefined);
};

export type HasSortOrderProperty = { sortOrder: number };
export const sortBySortOrder = <T extends HasSortOrderProperty>(items: T[]): T[] => {
  return items.slice().sort((a, b) => a.sortOrder - b.sortOrder);
};
