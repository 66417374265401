import { googleFontVariations } from '@apps/guest/packages/layout-engine/common/fonts';
import { layoutConfigs } from '@apps/guest/packages/layout-engine/layouts/layout.constants';
import { EventDesignFragment, EventPageFragment, EventPageVisibility, EventWebsiteGuestTelemetryQuery, DesignLayoutType } from '@graphql/generated';
import { Theme } from '@withjoy/joykit';
import globalWindow from '@shared/core/globals';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { withWindow } from '@shared/utils/withWindow';
import { getURLParams } from '@shared/utils/urlHelpers';
import { SearchParams } from './GuestSite.types';
import { useFeatureValue } from '@shared/core/featureFlags';
import { GuestSiteEvent } from '@apps/guest/packages/layout-engine/layouts/layout.types';
import { isInIframe } from '@shared/utils/isInIframe';
import useSet from '@shared/utils/hooks/useSet';
import { EnabledPagesInfo, GuestSiteLoadTelemetry, SecurityInfo } from '@apps/guest/GuestSite.telemetry';
import { ReservedRoomBlocks } from '@apps/guest/packages/layout-engine/widgets/Custom/components/TravelMap/TravelMap.types';
import { isBefore, isAfter, addYears, differenceInCalendarDays } from 'date-fns';
import { ExtendedEventUserStatus } from '@shared/components/AuthProvider/AuthProvider.utils';
import { useGuestSiteQueryParams } from '@apps/guest/packages/layout-engine/common/utils/useGuestSiteQueryParams';

type UseEventDesignThemingOptions = Readonly<{
  eventDesign: Maybe<EventDesignFragment>;
}>;

export const useEventDesignTheming = ({ eventDesign }: UseEventDesignThemingOptions) => {
  const layoutConfig = eventDesign?.websiteLayout.layoutType === 'brannan' ? layoutConfigs.brannan : layoutConfigs.aloha;
  const [theme, setTheme] = useState<Theme>(eventDesign ? layoutConfig.configureThemeOverrides(eventDesign, layoutConfig.theme) : layoutConfig.theme);
  const [_, { addMultiple: addFonts, has: hasFont }] = useSet();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (eventDesign && layoutConfig) {
      // Collect fonts to load
      const layoutFontFamilies = layoutConfig.fonts.google?.map(key => googleFontVariations[key]) || [];
      const eventDesignFontFamilies = [eventDesign.font.fontFamily];

      // Update JoyKit with `eventDesign` applied to a layout, otherwise use the default layout theme
      const updateTheme = () => {
        setTheme(layoutConfig.configureThemeOverrides(eventDesign, layoutConfig.theme));
      };
      const arrFonts = [...layoutFontFamilies, ...eventDesignFontFamilies].filter(x => x) as string[];
      if (globalWindow.WebFont) {
        const getFontsUnloaded = arrFonts.filter((font: string) => !hasFont(font));
        if (!getFontsUnloaded.length) {
          updateTheme();
          return;
        }

        setLoading(true);
        // 1. Attempt to load fonts first to prevent FOUT
        // 2. Update theme regardless of font load success
        globalWindow.WebFont?.load({
          google: {
            families: getFontsUnloaded
          },
          // Either the `active` or `inactive` callback will be invoked
          active: () => {
            addFonts(getFontsUnloaded);
            setLoading(false);
            updateTheme();
          },

          inactive: () => {
            setLoading(false);
            updateTheme();
          }
        });
      } else {
        updateTheme();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDesign, layoutConfig, setTheme]);

  return { theme, loadingFonts: loading };
};

export const shouldUseEventDesignDraft = () =>
  withWindow<boolean>(() => {
    const params = getURLParams<SearchParams>(window.location.search);
    /**
     * only use event design draft if:
     * - rendering for a preview
     * - the preview is for the website designer
     * effectively only request the event design draft if you are on the website designer
     */
    return params.preview === 'true' && params.ctx === 'websiteDesigner' ? true : false;
  }, false);

export const useReloadForLegacyGuestsite = (
  eventFeatureFlags: GuestSiteEvent['featureFlags'],
  website: Maybe<string>,
  useLegacyGuestSite: GuestSiteEvent['useLegacyGuestSite']
) => {
  const useCharmFeatureFlagFromTable = eventFeatureFlags && eventFeatureFlags.includes('useCharm');
  const useLegacyGuestSiteFeatureFlagFromTable = eventFeatureFlags && eventFeatureFlags.includes('useLegacyGuestSite');
  const isCharm = useFeatureValue('guestsitetarget') === 'charm';
  const noSsr = useFeatureValue('nossr');
  /**
   * If the event has 'useCharm' defined in the feature_flags table then
   * - proceed to CHARM guest site (do not reload)
   * If the event does not have 'useCharm'
   * - proceed to LEGACY guest site if the event has 'useLegacyGuestSite' defined in the feature_flags table (reload)
   * - proceed to LEGACY guest site if the event's useLegacyGuestSite query returns true
   */
  useEffect(() => {
    if (!useCharmFeatureFlagFromTable && (useLegacyGuestSiteFeatureFlagFromTable || useLegacyGuestSite)) {
      withWindow(window => {
        const params = getURLParams<SearchParams>(window.location.search);
        if (
          params.ctx === 'adminGuestSitePreview' ||
          params.preview === 'true' ||
          params.target === 'charm' ||
          noSsr ||
          /**
           * assume that if you are in an iframe you should be rendering in a preview
           * state and you should therefore always render the guest app instead of app-browser
           */
          isInIframe() ||
          isCharm ||
          // special events passing through to charm guest site
          website === 'richardtait'
        ) {
          return;
        } else {
          // render the app-browser site by reloading the page allowing charm server to kick the user to nginx
          window.location.reload();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCharm, noSsr, eventFeatureFlags]);
};

interface Pages extends Omit<EventPageFragment, 'id' | 'type'> {
  visibility: EventPageVisibility;
  private: boolean;
}

export const useHotelTelemetryUtils = (reservedRoomBlocksData?: ReservedRoomBlocks) => {
  const availableCount = reservedRoomBlocksData?.length || 0;
  let currentSoonestDate = addYears(new Date(), 2);

  const soonestRoomBlockCutoffDate = () => {
    reservedRoomBlocksData &&
      reservedRoomBlocksData?.forEach(room => {
        if (room.cutoffDate) {
          // should be the soonest in the _future_
          if (isBefore(new Date(room.cutoffDate), currentSoonestDate) && isAfter(new Date(room.cutoffDate), new Date())) {
            currentSoonestDate = new Date(room.cutoffDate);
          } else {
            currentSoonestDate = currentSoonestDate;
          }
        } else {
          currentSoonestDate = new Date();
        }
      });

    return currentSoonestDate;
  };

  const daysUntilSoonestCutOff = availableCount === 0 ? undefined : differenceInCalendarDays(soonestRoomBlockCutoffDate(), new Date());

  return { availableCount, daysUntilSoonestCutOff };
};

export const useGenerateGuestSiteLoadTelemData = (data: EventWebsiteGuestTelemetryQuery['eventByName'], role: ExtendedEventUserStatus): GuestSiteLoadTelemetry => {
  const telemPages = data?.pages;
  const leadState = data?.hotelRoomBlocks?.leadStateV2;
  const queryParams = useGuestSiteQueryParams();

  const useEnabledPages = useCallback((pages?: Pages[]): EnabledPagesInfo[] => {
    const pagesEnabled: EnabledPagesInfo[] = [];
    pages?.forEach(page => {
      if (page?.disabled === false) {
        pagesEnabled.push({ slug: page.pageSlug, title: page.pageTitle, visibility: page.visibility });
      }
    });
    return pagesEnabled;
  }, []);

  const useEventSecurityStatus = useCallback((pages?: Pages[], hasUnlocked?: boolean): SecurityInfo => {
    const passwordProtectedPages = [];
    pages?.forEach(page => {
      if (page?.private) {
        passwordProtectedPages.push(page);
      }
    });

    const requiresPassword = passwordProtectedPages.length > 0 ? true : false;
    const isUnlocked = passwordProtectedPages.length === 0 ? undefined : hasUnlocked;
    return { requiresPassword, isUnlocked };
  }, []);

  // existing isAdmin conflates superAdmin and admin role, for telem we specifically care about the distinction
  const getRole = (role: ExtendedEventUserStatus) => {
    if (role == 'superAdmin') {
      return 'super admin';
    } else if (role === 'admin') {
      return 'admin';
    } else {
      return 'guest';
    }
  };

  const useDaysUntilEvent = (finalizedEventDate: string | undefined): number | undefined => {
    const daysUntilEvent = finalizedEventDate ? differenceInCalendarDays(new Date(finalizedEventDate), new Date()) : undefined;
    return daysUntilEvent;
  };

  const enabledPages = useEnabledPages(telemPages);
  const { requiresPassword, isUnlocked } = useEventSecurityStatus(telemPages, data?.activeSession.hasUnlocked);
  const { availableCount, daysUntilSoonestCutOff } = useHotelTelemetryUtils(data?.hotelRoomBlocks?.reservedRoomBlocks || []);
  const daysUntilEvent = useDaysUntilEvent(data?.info?.finalizedDate?.dateString) || undefined;
  const extraInfo = useMemo(() => {
    return {
      layout: data?.eventDesign?.websiteLayout.layoutType || DesignLayoutType.aloha,
      userRole: getRole(role),
      security: {
        requiresPassword,
        isUnlocked
      },
      enabledPages: enabledPages,
      daysUntilEvent,
      roomBlocks: {
        leadState: leadState || undefined,
        availableCount,
        daysUntilSoonestCutOff
      },
      ...queryParams
    };
  }, [
    availableCount,
    daysUntilSoonestCutOff,
    enabledPages,
    isUnlocked,
    leadState,
    requiresPassword,
    role,
    data?.eventDesign?.websiteLayout.layoutType,
    daysUntilEvent,
    queryParams
  ]);

  return extraInfo;
};
